@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
:root {
  --font-size-s: 0.875rem;
  --font-size-m: 1rem;
  --font-size-l: 1.125rem;
  --font-size-xl: 2.5rem;
}

html,
body,
#root,
.App {
  height: 100%;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: #fcfcfc;
}

label {
  font-weight: bold;
}

.chat-area,
.chat-area {
  padding: 20px;
  height: 400px;
  overflow-y: scroll;
}

.chat-area .chat-bubble {
  background-color: #bbdefb;
  padding: 20px;
  border-radius: 4px;
  max-width: 300px;
  word-wrap: break-word;
}

.chat-bubble .chat-time {
  font-size: 12px;
  color: #757575;
  text-align: right;
}

.chat-area .chat-bubble.current-user {
  margin-left: auto;
  background-color: #a5d6a7;
}

.form-control {
  width: 60%;
}

.form-control:focus {
  box-shadow: none;
}

.app-nav-link {
  color: rgba(255, 255, 255);
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-right: 1rem;
  /* font-size: var(--font-size-s); */
  white-space: nowrap;
}

.app-nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}

.btn {
  margin-top: 10px;
}

.btn-submit {
  background-color: #2e7d32;
  color: white;
}

@media screen and (max-width: 767px) {
  .form-control {
    width: 100%;
  }

  .chat-area .chat-bubble {
    max-width: 250px;
  }
}


